import React, { useState, useEffect } from 'react';
import { axiosAT } from '../../interceptors/axiosInter';
import { convertToLocalTime } from '../utils/timeUtils';


const OutputTemplatesTab = () => {
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);


    // Fetch already uploaded files
    const fetchTemplates = async () => {
        const url = process.env.REACT_APP_BACKEND_URL + `/api/v1/all_output_templates`;
        try {
            const response = await axiosAT.get(url);
            setFiles(response.data);
        } catch (error) {
            console.error('There was an error fetching the templates!', error);
        }
    };

    useEffect(() => {
        fetchTemplates();
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            setSelectedFile(file);
        } else {
            alert('Iba dokumenty vo formáte .docx (Word dokument) sú podporované.');
        }
    };

    const handleFileUpload = () => {
        if (selectedFile) {
            setIsUploading(true);
            const formData = new FormData();
            formData.append('file', selectedFile);
            const url = process.env.REACT_APP_BACKEND_URL + `/api/v1/upload_template`;
            axiosAT.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    if (response.data.success) {
                        setSelectedFile(null); // Clear the selected file
                        fetchTemplates();
                    }
                })
                .catch(error => {
                    console.error('There was an error uploading the file!', error);
                })
                .finally(() => {
                    setIsUploading(false);
                });
        }
    };

    const handleFileDelete = (index) => {
        const fileToDelete = files[index];
        const url = process.env.REACT_APP_BACKEND_URL + `/api/v1/delete_template`;

        axiosAT.post(url, { file_id: fileToDelete.RowKey })
            .then(response => {
                if (response.data.success) {
                    const newFiles = files.filter((_, i) => i !== index);
                    setFiles(newFiles);
                }
            })
            .catch(error => {
                console.error('There was an error deleting the file!', error);
            });
    };


    const mapStatusToSlovak = (status) => {
        switch (status) {
            case 'processed':
                return 'spracované';
            case 'pending':
                return 'čakajúce';
            case 'failed':
                return 'neúspešné';
            default:
                return status;
        }
    };

    return (
        <div className="container mt-5">
      <h2 className='mb-4'>MS Word dokumenty slúžiace ako vzor pre generáciu výstupov</h2>
      <p className='mb-4'>Na tomto mieste môžeš vložiť MS Word dokumenty, napr. tvoju zápisnicu zo schôdze vytvorenú manuálne. Štruktúra a formát z týchto vzorových dokumentov môžu byť použité pri generácii výstupov z našej aplikácie "Radšej To Nahraj". Pri tvorbe novej aktivity si budeš môcť zvoliť ktorýkoľvek z týchto vzorov v sekcii "Vzor výstupu".</p>

            <div className="card">
                <div className="card-header">
                    <h3>Vlož Vzor Výstupu</h3>
                </div>
                <div className="card-body">
                    <input type="file" className="form-control" onChange={handleFileChange} accept=".docx" />
                    <button className="btn btn-primary mt-3" onClick={handleFileUpload} disabled={isUploading}>
                        {isUploading ? 'Nahrávam...' : 'Potvrdiť'}
                    </button>
                </div>
            </div>
            <div className="mt-4">
                <h4>Vložené súbory</h4>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Názov súboru</th>
                            <th scope="col">Dátum vytvorenia</th>
                            <th scope="col">Stav</th>
                            <th scope="col">Akcia</th>
                        </tr>
                    </thead>
                    <tbody>
                        {files.map((file, index) => (
                            <tr key={file.RowKey}>
                                <td>{file.filename}</td>
                                <td>{convertToLocalTime(file.created_at)}</td>
                                <td>{mapStatusToSlovak(file.status)}</td>
                                <td>
                                    <button className="btn btn-danger" onClick={() => handleFileDelete(index)}>
                                        Vymazať
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OutputTemplatesTab;