import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { axiosAT } from '../../interceptors/axiosInter'

import { useLocation, useNavigate } from 'react-router-dom';

const CreateActivityPage = () => {
  const location = useLocation();
  const { header, body, activity_type } = location.state || {};
  const navigate = useNavigate();

  const [file, setFile] = useState(null);
  const [instructions, setInstructions] = useState('');
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState('');
  const [isInstructionsCollapsed, setIsInstructionsCollapsed] = useState(activity_type !== 'at_general');
  const [errorMessage, setErrorMessage] = useState('');
  const [subscriptionInfo, setSubscriptionInfo] = useState('');
  const [outputTemplate, setOutputTemplate] = useState(''); // selected output template
  const [templates, setTemplates] = useState([]); // available output templates

  // useEffect(() => {
  //   const url = process.env.REACT_APP_BACKEND_URL + `/api/v1/subscription_status`;
  //   axiosAT.get(url)
  //     .then(response => {
  //       // setData(response.data);
  //       if (response.data.success) {
  //         let sub = response.data.subscription;
  //         if (sub) {
  //           if (sub.is_test) {
  //             setSubscriptionInfo('Nemáš aktivovaný žiadny predplatený balík spotreby. Momentálne využívaš len testovaciu verziu, ktorá nie je vhodná na prácu s citlivými dátami.');
  //           }
  //         } else {
  //           setSubscriptionInfo('Nemáš aktivovaný žiadny predplatený balík spotreby. Ak si ho neaktivuješ, tak nebudeš môcť využívať túto funkciu.');
  //         }
  //       }
  //     })
  //     .catch(error => {
  //       console.error('There was an error fetching the data!', error);
  //     });
  // }, []);


  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      const url = process.env.REACT_APP_BACKEND_URL + `/api/v1/subscription_status`;
      try {
        const response = await axiosAT.get(url);
        if (response.data.success) {
          let sub = response.data.subscription;
          if (sub) {
            if (sub.is_test) {
              setSubscriptionInfo('Nemáš aktivovaný žiadny predplatený balík spotreby. Momentálne využívaš len testovaciu verziu, ktorá nie je vhodná na prácu s citlivými dátami.');
            }
          } else {
            setSubscriptionInfo('Nemáš aktivovaný žiadny predplatený balík spotreby. Ak si ho neaktivuješ, tak nebudeš môcť využívať túto funkciu.');
          }
        }
      } catch (error) {
        console.error('There was an error fetching the data!', error);
      }
    };

    const fetchTemplates = async () => {
      const url = process.env.REACT_APP_BACKEND_URL + `/api/v1/output_templates`;
      try {
        const response = await axiosAT.get(url);
        setTemplates(response.data);
      } catch (error) {
        console.error('There was an error fetching the templates!', error);
      }
    };

    fetchSubscriptionStatus();
    fetchTemplates();

  }, []);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleInstructionsChange = (event) => {
    setInstructions(event.target.value);
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleInstructionsToggle = () => {
    setIsInstructionsCollapsed(!isInstructionsCollapsed);
  };

  const handleOutputTemplateChange = (event) => {
    setOutputTemplate(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      alert('Prosím, nahrajte súbor.');
      return;
    }

    setLoading(true);
    setErrorMessage('');

    const formData = new FormData();
    formData.append('file', file);
    formData.append('instructions', instructions);
    formData.append('note', note);
    formData.append('activity_type', activity_type);
    formData.append('outputTemplate', outputTemplate);

    try {
      const url = process.env.REACT_APP_BACKEND_URL + `/api/v1/create_activity`;
      const response = await axiosAT.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.data.success) {
        navigate(`/aktivita/${response.data.activity_id}`);
      } else {
        setErrorMessage(response.data.message);
      }

    } catch (error) {
      console.error('Error uploading file:', error);
      setErrorMessage('Pri nahrávaní súboru došlo k chybe. Skúste to prosím znova.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      {subscriptionInfo && (
        <div className="alert alert-warning" role="alert">
          {subscriptionInfo}
        </div>
      )}
      <h1 className="text-center mb-4">Vytvorenie novej aktivity</h1>

      {errorMessage && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}

      <div className="card">
        <div className="card-body">

          <div className="mb-3">
            <h2>{header}</h2>
            <p>{body}</p>
          </div>

          <h4 className="card-title mt-4">Audio nahrávka</h4>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="fileUpload" className="form-label">
                Nahraj audio súbor. Odporúčame formát mp3 alebo wav.<span className="text-danger">*</span>
              </label>
              <input
                type="file"
                className="form-control"
                id="fileUpload"
                onChange={handleFileChange}
                required
                accept=".wav,audio/wav,.mp3,audio/mp3,.aiff,audio/aiff,.aac,audio/aac,.ogg,audio/ogg,.flac,audio/flac,.m4a,audio/m4a"
              />
            </div>

            <h4 className="card-title mt-4">Vlastná poznámka</h4>
            <div className="mb-3">
              <label htmlFor="noteField" className="form-label">Táto poznámka nemá žiadny vplyv na vygenerovaný výstup. Je to len poznámka viditeľná v tvojej histórii aktivít.</label>
              <input type="text" className="form-control" id="noteField" placeholder="Zadajte poznámku" value={note} onChange={handleNoteChange} />
            </div>

            <h4 className="card-title mt-4">Vzor výstupu</h4>
            <div className="mb-3">
              <label htmlFor="outputTemplate" className="form-label">Toto pole nie je povinné. Je to tvoj vlastný Word dokument, podľa ktorého bude vygenerovaná štruktúra výstupu.</label>
              <select className="form-control" id="outputTemplate" value={outputTemplate} onChange={handleOutputTemplateChange}>
                <option value="">Žiadny</option>
                {templates.map((template) => (
                  <option key={template.RowKey} value={template.RowKey}>{template.filename}</option>
                ))}
              </select>
            </div>


            <h4 className="card-title mt-4" onClick={handleInstructionsToggle} style={{ cursor: 'pointer' }}>Dodatočné inštrukcie {isInstructionsCollapsed ? '▼' : '▲'}</h4>
            <div className="mb-3">
              {!isInstructionsCollapsed && (
                <>
                  {activity_type === 'at_general' ?
                    <>
                      <label htmlFor="longTextField" className="form-label">
                        Zadaj inštrukcie na spracovanie nahrávky napr. "Sumarizuj nahrávku a použi maximálne 100 slov." <span className="text-danger">*</span>
                      </label>
                      <textarea required className="form-control" id="longTextField" rows="6" placeholder="Zadajte detailné inštrukcie" value={instructions} onChange={handleInstructionsChange}></textarea>
                    </>
                    :
                    <>
                      <label htmlFor="longTextField" className="form-label">
                        Toto pole je dobrovoľné a <b>môže zostať prázdne</b>. Môžeš ho využiť na dodatočnú požiadavku na spracovanie záznamu napr. "Použi maximálne 100 slov."
                      </label>
                      <textarea className="form-control" id="longTextField" rows="6" placeholder="Zadajte detailné inštrukcie" value={instructions} onChange={handleInstructionsChange}></textarea>
                    </>
                  }
                </>
              )}
            </div>

            <button type="submit" className="btn btn-primary" disabled={loading}>
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className='me-2'
                  />
                  Potvrdzujem...
                </>
              ) : (
                'Potvrdiť'
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateActivityPage;
